import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { deleteProductById } from "../../api/firestoreApi";

const DeletePopup = ({ item, open, handleClose }) => {
  const queryClient = useQueryClient();
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteError, setDeleteError] = useState(null);

  useEffect(() => {
    setDeleteError(null);
  }, [open]);

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      const success = await deleteProductById(item.id);
      if (success) {
        setDeleteSuccess(true);
        queryClient.clear();
        queryClient.refetchQueries({
          queryKey: ["getAllProducts"],
        });

        setTimeout(() => {
          handleClose();
        }, 1000);
      } else {
        setDeleteError("Error deleting product: Deletion failed");
      }
    } catch (error) {
      console.error("Error deleting product:", error);
      setDeleteError("Error deleting product: " + error.message);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle fontSize={"large"} id="alert-dialog-title">
        {"Are you sure you want to delete this item?"}
      </DialogTitle>
      <DialogContent>
        {deleteSuccess ? (
          <>
            <DialogContentText fontSize={"large"}>
              Product deleted successfully!
            </DialogContentText>
          </>
        ) : (
          <>
            <DialogContentText
              fontSize={"medium"}
              id="alert-dialog-description"
            >
              {`SKU: ${item?.id}`}
              <br />
              {`Name: ${item?.title}`}
            </DialogContentText>
            {deleteError && (
              <DialogContentText fontSize={"medium"} color="error">
                {deleteError}
              </DialogContentText>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        {!deleteSuccess && (
          <>
            <Button
              onClick={handleClose}
              variant="outlined"
              disabled={isDeleting}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              color="error"
              variant="contained"
              disabled={isDeleting}
            >
              {isDeleting ? <CircularProgress size={24} /> : "Delete"}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DeletePopup;
