import { initializeApp } from "firebase/app";
import {
  addDoc,
  collection,
  deleteDoc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  listAll,
  ref,
  uploadBytes,
} from "firebase/storage";

const EMAIL_PUBLIC_KEY = process.env.REACT_APP_EMAIL_USER_ID;
const EMAIL_PRIVATE_KEY = process.env.REACT_APP_EMAIL_SERVICE_ID;
const API_KEY = process.env.REACT_APP_FIRESTORE_API_KEY;
const AUTH_DOMAIN = process.env.REACT_APP_FIRESTORE_AUTH_DOMAIN;
const PROJECT_ID = process.env.REACT_APP_FIRESTORE_PROJECT_ID;
const STORAGE_BUCKET = process.env.REACT_APP_FIRESTORE_STORAGE_BUCKET;
const MESSAGING_ID = process.env.REACT_APP_FIRESTORE_MESSAGING_SENDER_ID;
const APP_ID = process.env.REACT_APP_FIRESTORE_APP_ID;

const firebaseConfig = {
  apiKey: API_KEY,
  authDomain: AUTH_DOMAIN,
  projectId: PROJECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: MESSAGING_ID,
  appId: APP_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const getFirebaseStorage = getStorage(app);

const uploadImageToStorage = async (file, productId) => {
  const storage = getStorage();
  let storageRef;

  if (file.name === undefined) {
    storageRef = ref(storage, file);
  } else {
    storageRef = ref(storage, "images/" + file.name);
  }

  try {
    // Check if the file already exists in storage
    const existingFileSnapshot = await getDownloadURL(storageRef);
    return existingFileSnapshot;
  } catch (error) {
    // If the file doesn't exist, upload it to storage
    const newStorageRef = ref(storage, `images/${productId}_${file.name}`);
    await uploadBytes(newStorageRef, file);
    const downloadURL = await getDownloadURL(newStorageRef);
    return downloadURL;
  }
};

const removeImageFromStorage = async (imageToDelete) => {
  const storage = getStorage();
  const storageImages = await listAll(ref(storage, "images"));
  const storageImagePaths = storageImages.items.map(
    (reference) => reference.fullPath
  );

  const imageName = imageToDelete.substring(imageToDelete.lastIndexOf("/") + 1);
  const imageNameWithoutQuery = imageName.split("?")[0];
  const imageNameWithSpaces = imageNameWithoutQuery.replace(/%20/g, " ");
  const imagePath = imageNameWithSpaces.replace(/%2F/g, "/");

  if (storageImagePaths.includes(imagePath)) {
    await deleteObject(ref(storage, imagePath)).catch((err) =>
      console.error(err)
    );
  } else {
    console.log("Image does not exist in storage.");
  }
};

export const getAllProducts = async () => {
  try {
    const valRef = collection(db, "products");
    const docs = await getDocs(valRef);
    const productslist = docs.docs.map((doc) => doc.data());

    return productslist;
  } catch (error) {
    console.error("Error getting all products:", error);
    throw error;
  }
};

export const getProductById = async (productId) => {
  try {
    const productsRef = collection(db, "products");
    const q = query(productsRef, where("id", "==", productId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const product = querySnapshot.docs[0].data();
      return product;
    } else {
      console.log("No matching documents.");
      return null;
    }
  } catch (error) {
    console.error("Error getting product:", error);
    throw error;
  }
};

export const createNewProduct = async (data) => {
  try {
    const imageURLs = [];

    if (data.images && data.images.length > 0) {
      for (const image of data.images) {
        const imageURL = await uploadImageToStorage(image, data.id);
        imageURLs.push(imageURL);
      }
    }

    const productsCollectionRef = collection(db, "products");
    data.images = imageURLs;
    const docRef = await addDoc(productsCollectionRef, data);

    return docRef.id;
  } catch (error) {
    console.error("Error adding document: ", error);
    throw error;
  }
};

export const updateProductById = async (productId, newData) => {
  try {
    const productsRef = collection(db, "products");
    const q = query(productsRef, where("id", "==", productId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const productDoc = querySnapshot.docs[0];
      const existingData = productDoc.data();
      const imageURLs = [];

      if (existingData && existingData.images) {
        const imagesToDelete = existingData.images.filter(
          (image) => !newData.images.includes(image)
        );

        for (const imageToDelete of imagesToDelete) {
          removeImageFromStorage(imageToDelete);
        }
      }

      if (newData.images && newData.images.length > 0) {
        const imagesToUpload = newData.images.filter(
          (image) => !existingData.images.includes(image)
        );

        for (const imageToUpload of imagesToUpload) {
          const imageURL = await uploadImageToStorage(imageToUpload, productId);
          imageURLs.push(imageURL);
          newData.images.push(imageURL);
        }
      }

      const updatedImages = newData.images.filter(
        (image) =>
          imageURLs.includes(image) || existingData.images.includes(image)
      );

      const updatedNewData = { ...newData };
      updatedNewData.images = updatedImages;

      await updateDoc(productDoc.ref, updatedNewData);
      return true;
    } else {
      console.error(
        "No matching product found for the provided productId:",
        productId
      );
      return false;
    }
  } catch (error) {
    console.error("Error updating product:", error);
    throw error;
  }
};

export const deleteProductById = async (productId) => {
  try {
    // Retrieve product document
    const productsRef = collection(db, "products");
    const q = query(productsRef, where("id", "==", productId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const storage = getStorage();
      const docSnapshot = querySnapshot.docs[0];
      const productData = docSnapshot.data();

      // Delete images from Firebase Storage
      const imageURLs = productData.images;

      if (imageURLs.length > 0) {
        const deletionPromises = imageURLs.map(async (url) => {
          const imageRef = ref(storage, url);
          await deleteObject(imageRef);
        });

        await Promise.all(deletionPromises);
      }

      // Delete product document from Firestore
      await deleteDoc(docSnapshot.ref);
      return true;
    } else {
      console.log("No matching product found for deletion.");
      return false;
    }
  } catch (error) {
    console.error("Error deleting product:", error);
    throw error;
  }
};

export const getEmailHistory = async () => {
  try {
    const response = await fetch(
      `https://api.emailjs.com/api/v1.1/history?user_id=${EMAIL_PUBLIC_KEY}&accessToken=${EMAIL_PRIVATE_KEY}`
    );
    console.log(response);
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching history:", error);
  }
};
