import { useQuery } from "react-query";
import { getAllProducts } from "../api/firestoreApi";

export const useGetAllProducts = () => {
  return useQuery({
    queryFn: getAllProducts,
    queryKey: ["getAllProducts"],
    retry: 0,
    refetchOnWindowFocus: false,
  });
};
