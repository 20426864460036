import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css";

const USER_ID = process.env.REACT_APP_USER_ID;
const USER_PASSWORD = process.env.REACT_APP_USER_PASSWORD;

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://roeilulavy.github.io/portfolio/">
        Royal prod.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function Login() {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [isEmailError, setIsEmailError] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setUserId("");
    setUserPassword("");
    setErrorMessage("");
    setIsEmailError(false);
    setIsPasswordError(false);
    setIsLoading(false);
  }, []);

  const handleEmailKeyDown = (event) => {
    if (event.key === "Enter") {
      document.getElementById("password").focus();
    }
  };

  const handlePasswordKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    setErrorMessage("");
    setIsEmailError(false);
    setIsPasswordError(false);

    if (!userId) {
      setIsLoading(false);
      setIsEmailError(true);
      setErrorMessage("Please enter your Email");
    } else if (!userPassword) {
      setIsLoading(false);
      setIsPasswordError(true);
      setErrorMessage("Please enter your Password");
    } else {
      if (userId.toLowerCase() === USER_ID) {
        if (userPassword === USER_PASSWORD) {
          sessionStorage.setItem("userId", userId.toLowerCase());
          setTimeout(() => {
            setIsLoading(false);
            navigate("/products", { replace: true });
          }, 1000);
        } else {
          setIsLoading(false);
          setIsPasswordError(true);
          setErrorMessage("Incorrect Password");
        }
      } else {
        setIsLoading(false);
        setIsEmailError(true);
        setErrorMessage("Incorrect Email address");
      }
    }
  };

  return (
    <div className="Login">
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            padding: "10px 20px",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            boxShadow: "10px 10px 20px 0px rgba(0,0,0,0.4);",
            WebkitBoxShadow: "10px 10px 20px 0px rgba(0,0,0,0.4);",
            MozBoxShadow: "10px 10px 20px 0px rgba(0,0,0,0.4);",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={userId || ""}
              onChange={(e) => setUserId(e.target.value)}
              disabled={isLoading ? true : false}
              error={isEmailError ? true : false}
              helperText={isEmailError && errorMessage}
              onKeyDown={handleEmailKeyDown}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={userPassword || ""}
              onChange={(e) => setUserPassword(e.target.value)}
              disabled={isLoading ? true : false}
              error={isPasswordError ? true : false}
              helperText={isPasswordError && errorMessage}
              onKeyDown={handlePasswordKeyDown}
            />
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 3 }}
              onClick={handleSubmit}
              disabled={isLoading ? true : false}
            >
              {isLoading && (
                <CircularProgress size={25} sx={{ marginRight: 1 }} />
              )}
              Sign In
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 3, mb: 4 }} />
      </Container>
    </div>
  );
}

export default Login;
