import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import SideDrawer from "./components/SideDrawer/SideDrawer";
// import Emails from "./pages/Emails/Emails";
// import Home from "./pages/Home/Home";
import { useEffect } from "react";
import Login from "./pages/Login/Login";
import CreateProduct from "./pages/Products/CreateProduct/CreateProduct";
import EditProduct from "./pages/Products/EditProduct/EditProduct";
import Products from "./pages/Products/Products";
import ViewProduct from "./pages/Products/ViewProduct/ViewProduct";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    let sessionUserId = sessionStorage.getItem("userId");
    if (!sessionUserId) {
      navigate("/login", { replace: true });
    }
  }, [navigate]);

  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route element={<SideDrawer />}>
          {/* <Route path="/" element={<Home />} /> */}
          {/* <Route path="/emails" element={<Emails />} /> */}
          <Route path="/products" element={<Products />} />
          <Route path="/products/:id" element={<ViewProduct />} />
          <Route path="/products/:id/edit" element={<EditProduct />} />
          <Route path="/products/create-product" element={<CreateProduct />} />
        </Route>

        <Route path="/*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}

export default App;
