import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, CircularProgress, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DeletePopup from "../../../components/DeletePopup/DeletePopup";
import { useGetProductById } from "../../../hooks/useGetProductById";
import "./ViewProduct.css";

export default function ViewProduct() {
  const navigate = useNavigate();
  const location = useLocation();
  const productId = location.state.rowId;
  const { isLoading, error, data } = useGetProductById(productId);
  const [product, setProduct] = useState();
  const [productImage, setProductImage] = useState();
  const [open, setOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    if (data) {
      setProduct(data);
      setProductImage(data.images[0]);
    } else {
      navigate(-1);
    }
  }, [data, navigate]);

  function renderData(data) {
    return Object.entries(data)
      .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
      .map(([key, value], index) => {
        if (typeof value === "object") {
          return (
            <div key={index} className="Product_text">
              {renderData(value)}
            </div>
          );
        } else {
          return (
            <p key={index} className="Product_text">
              <span className="Product_span">• {key}:</span> {value}
            </p>
          );
        }
      });
  }

  const handleEdit = (rowId) => {
    navigate("./edit", { replace: false, state: { rowId } });
  };

  const handleOpen = () => {
    setItemToDelete(product);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setItemToDelete(null);
  };

  return (
    <section className="Product">
      <DeletePopup item={itemToDelete} open={open} handleClose={handleClose} />
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
          <div className="Product_button-container">
            <IconButton
              aria-label="back"
              size="large"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon fontSize="large" />
            </IconButton>
            <div>
              <Button variant="outlined" onClick={() => handleEdit(product.id)}>
                Edit
              </Button>
              <Button
                variant="contained"
                startIcon={<DeleteIcon />}
                color="error"
                onClick={handleOpen}
                sx={{ marginLeft: 2 }}
              >
                Delete
              </Button>
            </div>
          </div>
          <div className="Product_top">
            <div className="Product_image-container">
              <img
                className="Product_top_main-image"
                src={productImage}
                alt={""}
              />
              <div>
                {product?.images?.length > 0 &&
                  product?.images.map((image, index) => {
                    return (
                      <img
                        className="Product_top_image"
                        key={index}
                        src={image}
                        alt=""
                        onClick={() => setProductImage(image)}
                      />
                    );
                  })}
              </div>
            </div>
            <div className="Product_data">
              <h1 className="Product_title">{product?.title}</h1>
              <p className="Product_text">
                <span className="Product_span">• SKU:</span>
                {product?.id}
              </p>
              {product?.additionalData && renderData(product.additionalData)}
              <p className="Product_text">
                <span className="Product_span">• MOQ:</span> {product?.MOQ}
              </p>
            </div>
          </div>
        </>
      )}
    </section>
  );
}
