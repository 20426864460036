import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeletePopup from "../../components/DeletePopup/DeletePopup";
import { useGetAllProducts } from "../../hooks/useGetAllProducts";
import "./Products.css";

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "    ID",
  },
  {
    id: "image",
    numeric: false,
    disablePadding: false,
    label: "Image",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Title",
  },
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: "Category",
  },
  {
    id: "moq",
    numeric: false,
    disablePadding: false,
    label: "MOQ",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (array) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              borderBottom: "2px solid #cecece",
              fontWeight: "bold",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function Products() {
  const navigate = useNavigate();
  const { isLoading, data } = useGetAllProducts();
  const [productsList, setProductsList] = useState();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchId, setSearchId] = useState(
    sessionStorage.getItem("searchId") || ""
  );
  const [searchCategory, setSearchCategory] = useState(
    sessionStorage.getItem("searchCategory") || ""
  );
  const [searchCategoryList, setSearCategoryList] = useState([]);
  const [searchText, setSearchText] = useState(
    sessionStorage.getItem("searchText") || ""
  );
  const [open, setOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    let filteredProductsList = JSON.parse(
      sessionStorage.getItem("filteredProductsList")
    );

    if (data) {
      if (filteredProductsList && filteredProductsList.length > 0) {
        setProductsList(filteredProductsList);
      } else {
        setProductsList(data);
      }

      const categoriesSet = new Set();

      data.forEach((product) => {
        categoriesSet.add(product.category);

        setSearCategoryList(Array.from(categoriesSet));
      });
    }
  }, [data]);

  const handleFilter = () => {
    let filteredProducts = [...data];

    if (searchId) {
      filteredProducts = filteredProducts.filter(
        (product) => product.id === searchId
      );
    }

    if (searchCategory) {
      filteredProducts = filteredProducts.filter(
        (product) => product.category === searchCategory
      );
    }

    if (searchText) {
      const searchTextLowercase = searchText.toLowerCase();
      filteredProducts = filteredProducts.filter((product) =>
        product.title.toLowerCase().includes(searchTextLowercase)
      );
    }

    setProductsList(filteredProducts);
    sessionStorage.setItem(
      "filteredProductsList",
      JSON.stringify(filteredProducts)
    );
    sessionStorage.setItem("searchId", searchId);
    sessionStorage.setItem("searchCategory", searchCategory);
    sessionStorage.setItem("searchText", searchText);
  };

  const handleResetSearch = () => {
    setSearchText("");
    setSearchId("");
    setSearchCategory("");
    sessionStorage.removeItem("searchId");
    sessionStorage.removeItem("searchCategory");
    sessionStorage.removeItem("searchText");
    sessionStorage.removeItem("filteredProductsList");
    setProductsList(data);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - productsList?.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(productsList, getComparator(order, orderBy))?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [productsList, order, orderBy, page, rowsPerPage]
  );

  const handleAddNewProduct = () => {
    navigate("create-product", { replace: false });
  };

  const handleRowClick = (rowId) => {
    navigate(`${rowId}`, { replace: false, state: { rowId } });
  };

  const handleRowEdit = (rowId) => {
    navigate(`${rowId}/edit`, { replace: false, state: { rowId } });
  };

  const handleOpen = (row) => {
    setItemToDelete(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setItemToDelete(null);
  };

  return (
    <Box sx={{ width: "100%", height: "89vh" }}>
      <DeletePopup item={itemToDelete} open={open} handleClose={handleClose} />
      <div className="Producs_top-button-container">
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          color="info"
          onClick={handleAddNewProduct}
        >
          Create new product
        </Button>
      </div>

      <div className="Producs_Search-container">
        <TextField
          margin="normal"
          fullWidth
          id="id"
          label="Search by ID"
          name="id"
          onChange={(e) => setSearchId(e.target.value)}
          value={searchId || ""}
          sx={{ maxWidth: "500px", width: "10vw", padding: 0, margin: 0 }}
          size="small"
          type="search"
        />
        <FormControl
          sx={{
            maxWidth: "500px",
            width: "20vw",
            display: "flex",
            textAlign: "left",
          }}
        >
          <InputLabel id="search-by-category" size="small">
            Search by Category
          </InputLabel>
          <Select
            labelId="search-by-category"
            id="search-by-category"
            label="Search by Category"
            value={searchCategory || ""}
            onChange={(e) => setSearchCategory(e.target.value)}
            size="small"
          >
            {searchCategoryList.map((category, index) => (
              <MenuItem key={index} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
          {searchCategory && (
            <IconButton
              aria-label="Clear"
              color="primary"
              sx={{ position: "absolute", right: "25px", top: "7px" }}
              size="small"
              onClick={() => setSearchCategory("")}
            >
              <ClearIcon fontSize="inherit" />
            </IconButton>
          )}
        </FormControl>

        <TextField
          margin="normal"
          fullWidth
          id="text"
          label="Search by Title name"
          name="text"
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText || ""}
          sx={{ maxWidth: "500px", width: "40vw", padding: 0, margin: 0 }}
          size="small"
          type="search"
        />
        <Button
          variant="contained"
          startIcon={<SearchIcon />}
          disabled={isLoading ? true : false}
          color="info"
          onClick={handleFilter}
          sx={{
            maxWidth: "200px",
            fontSize: "14px",
          }}
        >
          Search
        </Button>
        <Button
          variant="outlined"
          startIcon={<RestartAltIcon />}
          disabled={isLoading ? true : false}
          color="info"
          onClick={handleResetSearch}
          sx={{
            maxWidth: "200px",
            fontSize: "14px",
          }}
        >
          Reset
        </Button>
      </div>

      {isLoading && <CircularProgress />}
      {productsList ? (
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer sx={{ maxHeight: "70vh", height: "65vh" }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"small"}
              stickyHeader
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={productsList?.length}
              />
              <TableBody>
                {visibleRows?.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        onClick={() => handleRowClick(row.id)}
                      >
                        {row.id}
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() => handleRowClick(row.id)}
                      >
                        {
                          <img
                            style={{ width: 60, height: 60 }}
                            src={row.images[0]}
                            alt={row.title}
                          />
                        }
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() => handleRowClick(row.id)}
                      >
                        {row.title}
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() => handleRowClick(row.id)}
                      >
                        {row.category}
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() => handleRowClick(row.id)}
                      >
                        {row.MOQ}
                      </TableCell>
                      <TableCell align="right">
                        {
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "right",
                              gap: 1,
                              zIndex: 2,
                            }}
                          >
                            <Button
                              variant="outlined"
                              onClick={() => handleRowEdit(row.id)}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="contained"
                              startIcon={<DeleteIcon />}
                              color="error"
                              onClick={() => handleOpen(row)}
                            >
                              Delete
                            </Button>
                          </Box>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={productsList?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        ""
      )}
    </Box>
  );
}
