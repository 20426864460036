import { useQuery } from "react-query";
import { getProductById } from "../api/firestoreApi";

export const useGetProductById = (id) => {
  return useQuery({
    queryFn: () => getProductById(id),
    queryKey: ["getProductById"],
    retry: 0,
    refetchOnWindowFocus: false,
  });
};
